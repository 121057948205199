@use "sass:meta" as ---r1t68ecjo7n;// Dark mode
$darkBlue: #141721;
$darkGrey: #343a4c;
$darkText: white;
$darkIcon: white;

// Light mode
$lightBlue: #E3F0F4; //#EDF6F9;
$lightDarkerBlue: #B5D1DB; //#CEE1E8;
$lightText: black;
$lightIcon: black;
$lightTableRowHover: #D9D9D9;


// BUTTONS
$border: 2px solid rgb(244, 245, 247);

$font-family-sans-serif: 'Be Vietnam', 'Roboto', 'Open Sans';

$headings-font-family: 'VAGRoundedBT', 'Roboto', 'Open Sans';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1200px,
  xxl: 1400px,
);

$dropdown-min-width: '4rem';

;@include ---r1t68ecjo7n.load-css("sass-embedded-legacy-load-done:1");