@import './styles/bootstrap.scss';

// @font-face {
//   font-family: 'VAGRoundedBT';
//   src: url('./fonts/VAGRoundedBT.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'BeVietnam';
//   src: url('./fonts/BeVietnam-Regular.ttf') format('truetype');
// }

@font-face {
  font-family: 'Inter';
  src: url('./fonts/InterVariable.ttf') format('truetype');
}

// @font-face {
//   font-family: 'BeVietnam';
//   src: url('./fonts/BeVietnam-Regular.ttf') format('truetype');
// }


body {
  margin: 0;
  // background-color: $darkGrey;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
}

h1 {
  font-size: 2.2rem;
}

h5 {
  font-size: 1rem;
}

span {
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.lc-shadow-1 {
  box-shadow: 0px 0px 3px 3px rgb(244, 245, 247);
}

::-webkit-scrollbar {
  height: 12px;
  width: 5px;
  background: #f9fafb;
}

::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.6);
  -webkit-border-radius: 2ex;
  -webkit-box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.5);
}